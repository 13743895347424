type ThrottleFn = (...args: any[]) => void

export function useThrottleFn(fn: ThrottleFn, delay: number) {
  let timeout: NodeJS.Timeout | null = null
  let lastExecutionTime = 0

  const throttledFn = (...args: any[]) => {
    const now = Date.now()

    if (now - lastExecutionTime >= delay) {
      fn(...args)
      lastExecutionTime = now
    } else {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(
        () => {
          fn(...args)
          lastExecutionTime = Date.now()
        },
        delay - (now - lastExecutionTime)
      )
    }
  }

  return throttledFn
}
