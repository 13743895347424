'use strict'

export const METHODS = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  patch: 'PATCH',
  delete: 'DELETE',
  options: 'OPTIONS'
} as const

export type Method = (typeof METHODS)[keyof typeof METHODS]

interface RequestTarget {
  url: string
  method: Method
}

export interface RequestBody {
  body: any
  params: any
}

type RequestInitWithoutBody = Omit<RequestInit, 'body' | 'params' | 'method'>

export interface Config extends RequestInitWithoutBody, Partial<RequestTarget>, Partial<RequestBody>, Partial<Interceptor> {
  baseURL?: string
  headers?: HeadersInit
  options?: Record<string, any>
  timeout?: number
}

export type FetchParams = string | string[][] | Record<string, string> | URLSearchParams

interface Interceptor
  extends Record<
    'interceptors',
    {
      request: RequestInterceptor[]
      response: ResponseInterceptor[]
      error: ErrorInterceptor[]
      timeout: TimeoutInterceptor[]
    }
  > {}

interface RequestInterceptor {
  (requestConfig: Partial<Config>): Partial<Config> | Promise<Partial<Config>>
}

interface ResponseInterceptor {
  (response: Response): Response | Promise<Response>
}

interface ErrorInterceptor {
  (error: Response): Response | Promise<Response>
}

interface TimeoutInterceptor {
  (timeout: number): void
}

interface Instance {
  instanciate: (options: Partial<Config>) => {
    create: <T>(method: Method, uri: string, ...requestConfig: Config[]) => Promise<Awaited<T>>
    interceptors: {
      timeout: (interceptor: TimeoutInterceptor, errorInterceptor?: ErrorInterceptor) => void
      request: (interceptor: RequestInterceptor, errorInterceptor?: ErrorInterceptor) => void
      response: (interceptor: ResponseInterceptor, errorInterceptor?: ErrorInterceptor) => void
    }
  }
}

const defaultHeaders: HeadersInit = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
}

const requestBody: RequestBody = {
  body: undefined,
  params: undefined
}

const requestTarget: RequestTarget = {
  url: '',
  method: 'GET'
}

const interceptor: Interceptor = {
  interceptors: {
    request: [],
    response: [],
    error: [],
    timeout: []
  }
}

const init: Config = {
  baseURL: '',
  headers: defaultHeaders,
  ...interceptor
}

function clear(a?: unknown[]) {
  do {
    a?.pop()
  } while (a && a.length > 0)
}

function timeout(interceptor: TimeoutInterceptor, error?: ErrorInterceptor) {
  init?.interceptors?.timeout?.push(interceptor)
  if (error) {
    init?.interceptors?.error?.push(error)
  }
}

function request(interceptor: RequestInterceptor, error?: ErrorInterceptor) {
  init?.interceptors?.request?.push(interceptor)
  if (error) {
    init?.interceptors?.error?.push(error)
  }
}

function response(interceptor: ResponseInterceptor, error?: ErrorInterceptor) {
  init?.interceptors?.response?.push(interceptor)
  if (error) {
    init?.interceptors?.error?.push(error)
  }
}

const interceptorsMap: Record<'request' | 'response' | 'error' | 'timeout', (value: any) => Promise<void>> = {
  request: async (value: Config) => {
    if (!init.interceptors) return
    for (const interceptor of init.interceptors.request) {
      await interceptor(value)
    }
  },
  response: async (response: Response) => {
    if (!init.interceptors) return
    for (const interceptor of init.interceptors.response) {
      await interceptor(response)
    }
  },
  error: async (error: any) => {
    if (!init.interceptors) return
    for (const interceptor of init.interceptors.error) {
      await interceptor(error)
    }
  },
  timeout: async (timeout: number) => {
    if (!init.interceptors) return
    for (const interceptor of init.interceptors.timeout) {
      interceptor(timeout)
    }
  }
}

function timeoutPromise<T>(ms: number, controller: AbortController): Promise<T> {
  return new Promise((_, reject) => {
    const timer = setTimeout(() => {
      if (!controller.signal.aborted) {
        controller.abort()
        interceptorsMap['timeout'](ms)
        reject(new Error('Request timed out'))
      }
    }, ms)

    controller.signal.addEventListener('abort', () => clearTimeout(timer))
  })
}

async function doRequest<T>(requestConfig: Config): Promise<T> {
  const config = { ...init, ...requestTarget, ...requestBody, ...requestConfig }
  const { url, timeout = 5000 } = config

  const controller = new AbortController()
  const signal = controller.signal
  config.signal = signal

  if (requestConfig.signal) {
    config.signal = requestConfig.signal
  }

  try {
    await interceptorsMap['request'](config)

    // Use Promise.race to handle both fetch and timeout
    const response = await Promise.race([fetch(url, config), timeoutPromise<Response>(timeout, controller)])

    // Handle response through interceptors and validate status
    await interceptorsMap['response'](response)

    if (!response.ok) {
      const errorMessage = response.statusText || 'Unknown error'
      throw new Error(errorMessage)
    }

    if (response.status === 204) {
      return {} as T // Return an empty object if no content
    }

    return (await response.json()) as T
  } catch (error) {
    await interceptorsMap['error'](error)
    return Promise.reject(error)
  } finally {
    // Clear interceptors regardless of the outcome
    clear(config.interceptors?.request)
    clear(config.interceptors?.response)
    clear(config.interceptors?.error)
    clear(config.interceptors?.timeout)
  }
}

function createRequestConfig(method: Method, uri: string, values: Config[]): Config {
  const combinedValues: Config = Object.assign({}, ...values)
  const url = new URL(uri, init.baseURL)

  // Handle parameters for the URL
  if (combinedValues?.params) {
    if (typeof combinedValues.params === 'string') {
      url.search = combinedValues.params
    } else if (typeof combinedValues.params === 'object') {
      url.search = new URLSearchParams(combinedValues.params).toString()
    } else if (Array.isArray(combinedValues.params)) {
      url.search = new URLSearchParams(combinedValues.params).toString()
    } else if (combinedValues?.params instanceof URLSearchParams) {
      url.search = combinedValues.params.toString()
    }
  }

  // Create the new config object
  const newConfig: Config = {
    ...init,
    ...requestTarget,
    ...requestBody,
    ...combinedValues,
    headers: {
      ...defaultHeaders,
      ...combinedValues.headers,
      ...init.headers
    },
    url: url.href,
    method,
    body: typeof combinedValues.body === 'object' ? JSON.stringify(combinedValues.body) : combinedValues.body
  }

  return newConfig
}

export const fetchInstance: Instance = {
  instanciate: (values: Config) => {
    Object.assign(init, values)

    return {
      create: async <T>(method: Method, uri: string, ...values: Config[]): Promise<Awaited<T>> => {
        const config = createRequestConfig(method, uri, values)
        return await doRequest<T>(config)
      },
      interceptors: {
        timeout,
        request,
        response
      }
    }
  }
}
