<script setup lang="ts">
import { selected } from '.';

import Active from '@/container/components/filters/bricks/components/Active.vue'
import Choice from '@/container/components/filters/bricks/components/Choice.vue'

import type { Form } from '@/services/form'
import type { CanvasFilter, RulesSet } from '=/types'

const show = defineModel<boolean>('show', { required: true })
const values = defineModel<Form<CanvasFilter>>('values', { required: true })

defineProps<{ rules?: RulesSet }>()
</script>

<template>
  <Active v-model:show="show" v-model:values="values" v-model:selected="selected" />
  <hr />
  <Choice v-model:show="show" v-model:values="values" v-model:selected="selected" :rules />
</template>

<style lang="scss">
.item.selected {
  border-radius: 8px;

  &.new::after {
    box-shadow: inset 0 0 0 2px #a8a29e;

  }

  &::after {
    box-shadow: inset 0 0 0 2px black, inset 0 0 0 4px white;
    border-radius: 8px;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
</style>