import { autoPlacement, autoUpdate, computePosition, arrow, type ReferenceElement } from '@floating-ui/dom'
import { createApp, h, markRaw, ref } from 'vue'

import type { ComponentPublicInstance, DirectiveBinding, Ref } from 'vue'

interface UseFloating {
  tooltip: Ref<HTMLElement | null>
  show: () => void
  hide: (el?: HTMLElement) => void
  readonly createInstance: (el: HTMLElement, binding: DirectiveBinding) => void
}

interface MapEvent {
  [key: string]: () => void
}

export function useFloating(): UseFloating {
  let element: ReferenceElement
  let directive: DirectiveBinding
  let instanceCreated: boolean = false

  const tooltipInstance = ref<ComponentPublicInstance | null>(null)
  const tooltip = ref<HTMLElement | null>(null)

  function createTooltip() {
    tooltip.value = document.createElement('div')
    tooltip.value.classList.add('tooltip')

    tooltip.value.style.opacity = '0'
    tooltip.value.style.position = 'absolute'
    tooltip.value.style.transition = 'opacity 0.3s ease-in-out'

    const tooltipEventMap = markRaw<MapEvent>({
      string: () => {
        if (!tooltip.value) return

        tooltip.value.innerText = directive.value[0]
        document.body.appendChild(tooltip.value)

        const arrowElement = document.createElement('div')
        arrowElement.id = 'arrow'
        tooltip.value.appendChild(arrowElement)
      },
      object: () => {
        if (!tooltip.value) return
        document.body.appendChild(tooltip.value)
        const float = createApp({ render: () => h(directive.value[0], directive.value[1] || {}) })
        tooltipInstance.value = float.mount(tooltip.value)

        const arrowElement = document.createElement('div')
        arrowElement.id = 'arrow'
        tooltip.value.appendChild(arrowElement)
      }
    })

    tooltipEventMap[typeof directive.value[0]]()

    async function update() {
      if (!tooltip.value) return
      const arrowElement: HTMLElement | null = tooltip.value.querySelector('#arrow')

      const { x, y, placement, middlewareData } = await computePosition(element, tooltip.value, {
        middleware: [
          autoPlacement(),
          arrow({
            element: arrowElement!
          })
        ]
      })

      Object.assign(tooltip.value.style, { left: `${x}px`, top: `${y}px` })

      console.log(middlewareData.arrow)

      if (middlewareData.arrow) {
        const { x: arrowX, y: arrowY } = middlewareData.arrow

        const staticSide = {
          top: 'bottom',
          right: 'left',
          bottom: 'top',
          left: 'right'
        }[placement.split('-')[0]] as string

        if (arrowElement) {
          Object.assign(arrowElement.style, {
            left: `${arrowX}px`,
            top: `${arrowY}px`,
            [staticSide]: '-5px'
          })
        }
      }
    }

    autoUpdate(element, tooltip.value, update)
  }

  function show() {
    createTooltip()
    if (!tooltip.value) return
    tooltip.value.style.opacity = '1'
  }

  function hide(el?: HTMLElement) {
    if (el) {
      el.style.opacity = '0'
      el.remove()
    }

    if (!tooltip.value) return
    tooltip.value.style.opacity = '0'
    tooltip.value.remove()
  }

  function createInstance(el: HTMLElement, binding: DirectiveBinding) {
    if (!instanceCreated) {
      instanceCreated = true

      element = el
      directive = binding
    } else {
      throw new Error('Instance has already been created')
    }
  }

  return {
    tooltip,
    show,
    hide,
    createInstance
  }
}
